import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';

import { createErrorNotification, createNotification, refreshToken } from '@/helper/ApiHelper';
import { AffiliateService } from '@/services/AffiliateService';
import ConfettiShower from '@/components/General/ConfettiShower';

export default function StepOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState(false);

  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  return (
    <div>
      <ConfettiShower />
      <div className={'px-6 max-w-4xl'}>
        <h2 className="text-2xl font-bold text-primary">{t('thanks.header')}</h2>

        <p className={'my-6 text-lg'}>{t('thanks.body')}</p>

        <p className="text-center">
          {t('thanks.questions_or_feedback')} <br />
          <a className="text-primary font-bold" href="mailto:support@workplan.digital">
            support@workplan.digital
          </a>
        </p>

        <div className="w-full flex justify-center mt-12">
          <div className={'btn btn-primary w-32'} onClick={() => navigate('/auth/login')}>
            {t('general.to') + ' ' + t('auth.login_page')}
          </div>
        </div>
      </div>
    </div>
  );
}
