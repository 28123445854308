import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { PaymentService } from '@/services/PaymentService';
import LicenseType from '@/components/Commerce/LicenseType';
import { DateTime } from 'luxon';

export interface LicenseSelectionProps {
  affiliateId?: null;
  setLicense: any;
  selectedNumberEmployees: any;
  setNumberEmployeees: any;
  selectedLicense: any;
}

export default function LicenseSelection({
  affiliateId = null,
  setLicense = () => {},
  setNumberEmployeees = () => {},
  selectedNumberEmployees = null,
  selectedLicense = null,
}: LicenseSelectionProps) {
  const { t } = useTranslation();
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [checkoutDisabled, setCheckoutDisabled] = useState(false);
  const [instantUpgrade, setInstantUpgrade] = useState(false);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState('');
  const [currentLicense, setCurrentLicense] = useState({});
  const [instantUpgradeSelected, setInstantUpgradeSelected] = useState(false);
  const [subscription, setSubscription] = useState({
    type: selectedLicense ?? 'workplan_team_license',
    number_employees: selectedNumberEmployees ?? 2,
  });

  const mapping = {
    1: 5,
    2: 10,
    3: 20,
    4: 30,
    5: 1,
    10: 2,
    20: 3,
    30: 4,
  };

  const getPrices = () => {
    setIsLoading(true);
    PaymentService.getPrices()
      .then((prices: any) => {
        setPrices(prices);
      })
      .catch((err) => createErrorNotification(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const licenseMapping = {
    workplan_team_license: 1,
    workplan_business_license: 2,
    workplan_enterprise_license: 3,
  };

  const employeeMapping = {
    1: 5,
    2: 10,
    3: 20,
    4: 30,
    5: 1,
    10: 2,
    20: 3,
    30: 4,
  };

  const getCurrentLicense = () => {
    PaymentService.getCurrentLicense(affiliateId)
      .then((res: any) => {
        setSubscription({
          type: res.name,
          number_employees: mapping[res.max_user],
        });

        setCurrentPeriodEnd(DateTime.fromISO(res.current_period_end).toFormat('dd.LL.'));
        setCurrentLicense({
          type: res.name,
          number_employees: employeeMapping[res.max_user],
        });
        checkInstantUpgrade(employeeMapping[res.max_user], res.name, {
          type: res.name,
          number_employees: employeeMapping[res.max_user],
        });
        setCheckoutDisabled(res.current_period_end === null);
      })
      .catch(createErrorNotification);
  };

  const redirectToCheckout = () => {
    PaymentService.redirectToCheckout(affiliateId, subscription.number_employees, subscription.type)
      .then((res) => {
        window.location = res;
      })
      .catch(createErrorNotification);
  };

  const checkInstantUpgrade = (
    employees = subscription.number_employees,
    type = subscription.type,
    old = currentLicense,
  ) => {
    const instantUpgrade =
      (licenseMapping[type] > licenseMapping[old.type] && employees >= old.number_employees) ||
      (licenseMapping[type] >= licenseMapping[old.type] && employees > old.number_employees);
    setInstantUpgrade(instantUpgrade);

    if (!instantUpgrade) {
      setInstantUpgradeSelected(false);
    }
  };

  const updateSubscription = () => {
    PaymentService.updateSubscription(
      affiliateId,
      subscription.type,
      employeeMapping[subscription.number_employees],
      instantUpgradeSelected,
    )
      .then((res) => {
        getCurrentLicense();
        createNotification('Subscription updated', 'Your subscription has been updated', 'success');
      })
      .catch(createErrorNotification);
  };

  useEffect(() => {
    getPrices();

    if (affiliateId !== null) {
      getCurrentLicense();
    }
  }, []);

  const setInternalLicense = (type) => {
    setLicense(type);
    checkInstantUpgrade(subscription.number_employees, type);
    setSubscription({ ...subscription, type });
  };

  const setInternalNumberEmployees = (number_employees) => {
    setNumberEmployeees(number_employees);
    checkInstantUpgrade(number_employees, subscription.type);
    setSubscription({ ...subscription, number_employees: number_employees });
  };

  return (
    <div className={'px-6 mx-auto'}>
      <h2 className="text-2xl font-bold text-primary">{t('onboarding.checkout.choose_license')}</h2>
      <p className={'my-6'}>{t('onboarding.checkout.switch_plan')}</p>

      <div className={'mt-10 flex flex-row justify-between'}>
        <p>{t('onboarding.checkout.how_many_employees')}</p>
        <a
          href={'mailto:sales@workplan.digital'}
          className={'link text-right text-gray-500 font-bold'}
        >
          {t('commerce.more_than_30')}
        </a>
      </div>
      <div className="form-control w-full mt-2">
        <input
          type="range"
          value={subscription.number_employees}
          min={1}
          max={4}
          onChange={(e) => setInternalNumberEmployees(e.target.value)}
          className="range range-primary"
        />
        <div className="w-full flex justify-between text-xs px-2 mt-2">
          <span>5</span>
          <span>10</span>
          <span>20</span>
          <span>30</span>
        </div>
      </div>

      <div className="mx-auto mb-20 mt-12">
        <div className="text-center xl:flex xl:flex-row space-x-0 xl:space-x-5 space-y-5 xl:space-y-0">
          <LicenseType
            title={t('commerce.licenses.team')}
            isSelected={subscription.type === 'workplan_team_license'}
            setSelected={() => setInternalLicense('workplan_team_license')}
            showCard={false}
            showProject={false}
            isLoading={isLoading}
            price={
              prices.workplan_team_license !== undefined
                ? prices?.workplan_team_license[subscription.number_employees].flat_amount / 100
                : 0
            }
            toggleBackground={false}
          />

          <LicenseType
            title={t('commerce.licenses.business')}
            isSelected={subscription.type === 'workplan_business_license'}
            setSelected={() => setInternalLicense('workplan_business_license')}
            showCard={false}
            showProject={true}
            isLoading={isLoading}
            price={
              prices.workplan_business_license !== undefined
                ? prices?.workplan_business_license[subscription.number_employees].flat_amount / 100
                : 0
            }
            toggleBackground={true}
          />

          <LicenseType
            title={t('commerce.licenses.enterprise')}
            isSelected={subscription.type === 'workplan_enterprise_license'}
            setSelected={() => setInternalLicense('workplan_enterprise_license')}
            showCard={true}
            showProject={true}
            isLoading={isLoading}
            price={
              prices.workplan_enterprise_license !== undefined
                ? prices?.workplan_enterprise_license[subscription.number_employees].flat_amount /
                  100
                : 0
            }
            toggleBackground={false}
          />
        </div>
      </div>

      <div>
        {affiliateId !== null && (
          <div>
            <p className={'text-gray-500'}>{t('commerce.instant_upgrade_description')}</p>

            <div className={'form-control mx-auto ' + (instantUpgrade ? '' : 'opacity-30')}>
              <label className="cursor-pointer label justify-center">
                <span className="label-text">{t('commerce.instant_upgrade')}</span>
                <input
                  disabled={!instantUpgrade}
                  checked={instantUpgradeSelected}
                  onChange={(e) => setInstantUpgradeSelected(!instantUpgradeSelected)}
                  type="checkbox"
                  className="checkbox checkbox-success ml-2"
                />
              </label>
            </div>

            <div
              className={
                'btn w-full ' +
                (checkoutDisabled ? 'btn-active btn-ghost cursor-not-allowed' : 'btn-primary')
              }
              onClick={() => updateSubscription()}
            >
              {instantUpgradeSelected
                ? t('commerce.upgrade_now')
                : t('commerce.change_until', { end: currentPeriodEnd })}
              {checkoutDisabled ? (
                <span className="loading loading-spinner loading-xs"></span>
              ) : (
                <BsArrowRight size={17} className="ml-2" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
