import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Shift, ShiftService } from '@/services/ShiftService';
import { useTranslation } from 'react-i18next';

export const EditShiftModal = ({ isModalOpen, setIsModalOpen, shiftId, reload }) => {
  const [editShift, setEditShift] = useState({} as Shift);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const { t } = useTranslation();

  const setShiftData = (evt) => {
    setEditShift({ ...editShift, [evt.target.name]: evt.target.value });
  };

  useEffect(() => {
    if (users.length === 0) {
      getCreateModal();
    }

    if (isModalOpen) {
      setEditShift({});
      getShift();
    }
  }, [isModalOpen]);

  const getCreateModal = () => {
    ShiftService.getModal(selectedAffiliate.id)
      .then((res: any) => {
        setUsers(res);
      })
      .catch((err) => {
        createErrorNotification(err);
      });
  };

  const getShift = () => {
    setIsLoading(true);

    ShiftService.getShift(shiftId)
      .then((res: any) => {
        let data = res;
        let selectedUser = users.filter((user) => user.id === data.user.id)[0];
        setProjects(selectedUser?.projects ?? []);

        setEditShift({
          id: data.id,
          user_id: data.user.id,
          project_id: data.project.id,
          note: data.note,
          shift_date: DateTime.fromISO(data.start).toFormat('y-LL-dd'),
          shift_start: DateTime.fromISO(data.start).toFormat('HH:mm'),
          shift_end: DateTime.fromISO(data.end).toFormat('HH:mm'),
        });
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateShift = () => {
    setIsSaving(true);

    ShiftService.updateShift(editShift.id, {
      user_id: editShift.user_id,
      note: editShift.note,
      project_id: editShift.project_id,
      start: DateTime.fromSQL(editShift.shift_date + ' ' + editShift.shift_start).toISO({
        suppressMilliseconds: true,
      }) as string,
      end: DateTime.fromSQL(editShift.shift_date + ' ' + editShift.shift_end).toISO({
        suppressMilliseconds: true,
      }) as string,
    })
      .then(() => {
        reload();
        setIsModalOpen(false);
      })
      .catch(createErrorNotification)
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal
      modalName="edit_shift"
      headline={t('time_tracking.edit_shift')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      onSafe={updateShift}
      primaryButtonText={'update'}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      {isLoading && (
        <div className={'flex justify-center my-4'}>
          <span className={'loading loading-lg loading-spinner'}></span>
        </div>
      )}

      {!isLoading && (
        <div className="space-y-1">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Benutzer</span>
            </label>
            <select
              name={'user_id'}
              value={editShift?.user_id}
              onChange={setShiftData}
              className="select select-bordered w-full max-w"
            >
              <option value={''}>{t('general.please_select')}</option>
              {users.map((user: any, key: number) => {
                return (
                  <option key={key} value={user.id}>
                    {user.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Datum</span>
            </label>
            <input
              type="date"
              name={'shift_date'}
              value={editShift?.shift_date}
              onChange={setShiftData}
              placeholder=""
              className="input input-bordered w-full"
            />
          </div>
          <div className={'grid grid-cols-2 space-x-2'}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Start</span>
              </label>
              <input
                type="time"
                name={'shift_start'}
                value={editShift?.shift_start}
                onChange={setShiftData}
                placeholder=""
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Ende</span>
              </label>
              <input
                type="time"
                name={'shift_end'}
                value={editShift?.shift_end}
                onChange={setShiftData}
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Projekt</span>
            </label>
            <select
              name={'project_id'}
              value={editShift?.project_id}
              onChange={setShiftData}
              className="select select-bordered w-full max-w"
            >
              <option value={''}>{t('general.please_select')}</option>
              {projects.map((project: any, key: number) => {
                return (
                  <option key={key} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Notiz</span>
            </label>
            <textarea
              name={'note'}
              onChange={setShiftData}
              value={editShift.note}
              className="textarea textarea-bordered h-10"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EditShiftModal;
