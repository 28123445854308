import React from 'react';

import { AuthLayout } from '@/components/Auth/AuthLayout';
import { AuthPageType } from '@/types/Auth/types.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmailPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <AuthLayout authType={AuthPageType.REGISTER}>
      <div className="w-[85%] max-w-[400px] mx-auto">
        <h2 className="text-2xl font-semibold py-2">{t('auth.confirm_email')}</h2>
        <div className={'text-gray-600 mb-3'}>
          {t('auth.check_your_email_for_registration_confirmation')}
        </div>
        <button
          className={'btn btn-primary text-white mt-3 w-full'}
          onClick={() => navigate('/auth/login')}
        >
          {t('general.to') + ' ' + t('auth.login_page')}
        </button>
      </div>
    </AuthLayout>
  );
}
