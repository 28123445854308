import React, { useEffect, useState } from 'react';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { BsPen, BsTrash } from 'react-icons/bs';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { CreateEmployeeModal } from '@/components/Modal/CreateEmployeeModal';
import { DeleteEmployeeModal } from '@/components/Modal/DeleteEmployeeModal';
import { DateTime } from 'luxon';
import { LiaClipboardSolid } from 'react-icons/lia';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DeleteEmployeeInvitation } from '@/components/Modal/DeleteEmployeeInvitation';
import { DropdownActions } from '@/components/General/DropdownActions';
import { ShiftService } from '@/services/ShiftService';
import { AffiliateService } from '@/services/AffiliateService';
import Navbar from '@/components/Menu/Navbar';
import { useNavigate } from 'react-router-dom';
import { MailIcon, PlusIcon } from 'lucide-react';
import { InviteEmployeeModal } from '@/components/Modal/InviteEmployeeModal';
import { useTranslation } from 'react-i18next';

export default function EmployeePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteInvitationModalOpen, setIsDeleteInvitationModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [inviteId, setInviteId] = useState('');

  const openProfilePage = (userId) => {
    navigate('/affiliate/employee/' + userId);
  };

  const deleteInvite = (inviteId, userName) => {
    setInviteId(inviteId);
    setUserName(userName);
    setIsDeleteInvitationModalOpen(true);
  };

  const openDeleteModal = (userId, userName) => {
    setUserId(userId);
    setUserName(userName);
    setIsDeleteModalOpen(true);
  };

  const loadOverview = () => {
    setIsLoading(true);
    ShiftService.getOverview(selectedAffiliate.id)
      .then((res: any) => {
        setUsers(Object.values(res));
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadInvites = () => {
    if (selectedAffiliate.isAdmin === false) {
      return;
    }

    AffiliateService.getInvites(selectedAffiliate.id)
      .then((res: any) => {
        setInvites(res);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally();
  };

  useEffect(() => {
    loadOverview();
    loadInvites();
  }, []);

  return (
    <div className={'w-full'}>
      {selectedAffiliate.isAdmin && (
        <div>
          <DeleteEmployeeInvitation
            isModalOpen={isDeleteInvitationModalOpen}
            setIsModalOpen={setIsDeleteInvitationModalOpen}
            inviteId={inviteId}
            reload={loadInvites}
            userName={userName}
          />

          <CreateEmployeeModal
            reloadUser={loadOverview}
            isModalOpen={isCreateModalOpen}
            setIsModalOpen={setIsCreateModalOpen}
          />

          <InviteEmployeeModal
            reloadInvites={loadInvites}
            isModalOpen={isInviteModalOpen}
            setIsModalOpen={setIsInviteModalOpen}
          />

          <DeleteEmployeeModal
            isModalOpen={isDeleteModalOpen}
            setIsModalOpen={setIsDeleteModalOpen}
            reload={loadOverview}
            userId={userId}
            userName={userName}
          />
          <Navbar
            end={
              <div className="p-4 w-full flex flex-row space-x-3 justify-end">
                <div
                  className={'btn btn-primary text-white hover:cursor-pointer'}
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  <PlusIcon size={17} /> {t('employee_page.create_employee')}
                </div>
                <div
                  className={'btn btn-primary text-white hover:cursor-pointer'}
                  onClick={() => setIsInviteModalOpen(true)}
                >
                  <MailIcon size={17} /> {t('employee_page.invite_per_email')}
                </div>
              </div>
            }
          />
        </div>
      )}
      <div>
        {isLoading ? (
          <LoadingComponent paddingTop={true} />
        ) : (
          <table className="table table-zebra table-fixed w-full">
            <thead>
              <tr>
                <th>{t('general.table_headers.name')}</th>
                <th>{t('general.table_headers.hours')}</th>
                <th>{t('general.table_headers.holiday')}</th>
                <th>{t('general.table_headers.saldo')}</th>
                <th className={'hidden lg:table-cell'}>{t('general.table_headers.start')}</th>
                {selectedAffiliate.isAdmin && <th></th>}
              </tr>
            </thead>
            <tbody>
              {users.map((user: any, key: number) => {
                return (
                  <tr key={key} className={selectedAffiliate.isAdmin ? undefined : 'h-12'}>
                    <td className={'overflow-scroll lg:overflow-hidden'}>{user.name}</td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {user.weekly_hours} <span className={'text-gray-500'}>h</span>
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {user.vacation_days || 0} <span className={'text-gray-500'}>T</span>
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      <span
                        className={
                          parseFloat(user.saldo.amount) >= 0 ? 'text-primary' : 'text-error'
                        }
                      >
                        {user.saldo.amount}
                      </span>{' '}
                      <span className={'text-gray-500'}>{user.saldo.unit}</span>
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden hidden lg:table-cell'}>
                      {user.start_date
                        ? DateTime.fromISO(user.start_date).toFormat('dd.LL.y')
                        : '-'}
                    </td>
                    {selectedAffiliate.isAdmin && (
                      <td className={'h-full'}>
                        <DropdownActions
                          actions={[
                            {
                              title: t('general.table_actions.edit'),
                              icon: <BsPen className={'hover:cursor-pointer'} size={17} />,
                              action: () => openProfilePage(user.id),
                            },
                            {
                              title: t('general.table_actions.delete'),
                              icon: (
                                <BsTrash className={'text-error hover:cursor-pointer'} size={17} />
                              ),
                              action: () => openDeleteModal(user.id, user.name),
                            },
                          ]}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      {selectedAffiliate.isAdmin && invites.length !== 0 && (
        <>
          <div className={'text-lg p-6'}>{t('employee_page.invitations')}</div>
          <table className="table table-zebra table-fixed border-t border-t-base-300">
            <thead>
              <tr>
                <th>{t('general.table_headers.name')}</th>
                <th>{t('general.table_headers.mail')}</th>
                <th>{t('general.table_headers.expiration_date')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invites.map((invite: any, key) => {
                return (
                  <tr key={key}>
                    <td className={'overflow-scroll lg:overflow-hidden'}>{invite.name}</td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>{invite.email}</td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {DateTime.fromISO(invite.expiration_date).toFormat('dd.LL.y')}
                    </td>
                    <td>
                      <DropdownActions
                        actions={[
                          {
                            title: t('general.table_actions.copy'),
                            icon: (
                              <CopyToClipboard
                                text={
                                  window.location.protocol +
                                  '//' +
                                  window.location.host +
                                  '/auth/join-affiliate/' +
                                  invite.token
                                }
                                onCopy={() =>
                                  createNotification(
                                    t('employee_page.notifications.copy_invitations_header'),
                                    t('employee_page.notifications.copy_invitations_message'),
                                    'success',
                                  )
                                }
                              >
                                <LiaClipboardSolid size={17} className={'hover:cursor-pointer'} />
                              </CopyToClipboard>
                            ),
                            action: () => {},
                          },
                          {
                            title: t('general.table_actions.delete'),
                            icon: <BsTrash size={17} className={'text-error'} />,
                            action: () => deleteInvite(invite.id, invite.email),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
