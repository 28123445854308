import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import { createErrorNotification } from '@/helper/ApiHelper';
import { AffiliateService } from '@/services/AffiliateService';
import { PaymentService } from '@/services/PaymentService';

export default function StepFive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const onboarding = useSelector((state: any) => state.onboarding);
  const [startedProgress, setStartedProgress] = useState({
    affiliate: false,
    configuration: false,
    checkout: false,
  }) as any;

  const [progressBar, setProgressBar] = useState({
    affiliate: 0,
    configuration: 0,
    checkout: 0,
  }) as any;

  useEffect(() => {
    dispatch(setStep(5));
  }, []);

  const updateProgress = () => {
    if (startedProgress.affiliate && progressBar.affiliate < 95) {
      progressBar.affiliate += 5;
    }

    if (!startedProgress.affiliate && progressBar.affiliate > 0) {
      progressBar.affiliate = 100;
    }

    if (startedProgress.configuration) {
      progressBar.configuration += 5;
    }

    if (!startedProgress.configuration && progressBar.configuration > 0) {
      progressBar.configuration = 100;
    }

    if (startedProgress.checkout) {
      progressBar.checkout += 5;
    }

    if (!startedProgress.checkout && progressBar.checkout > 0) {
      progressBar.checkout = 100;
    }

    setProgressBar({ ...progressBar });
  };

  useEffect(() => {
    gotoCheckout();
    const interval = setInterval(() => updateProgress(), 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const gotoCheckout = () => {
    //startedProgress.checkout = true;
    //setStartedProgress(startedProgress);
    setIsLoading(true);

    PaymentService.getPaymentLink({
      affiliate: onboarding.affiliate,
      number_employees: parseInt(employeeMapping[onboarding.subscription.number_employees]),
      license_type: onboarding.subscription.type,
    })
      .then((res: any) => {
        setCheckoutUrl(res.checkout_url);
      })
      .catch(createErrorNotification)
      .finally(() => {
        setIsLoading(false);
        //startedProgress.checkout = false;
        //setStartedProgress(setStartedProgress);

        //progressBar.checkout = 100;
        //setProgressBar(progressBar);
      });
  };

  const previousStep = () => {
    navigate('/onboarding/select-license');
  };

  const nameMapping = {
    workplan_team_license: 'Team',
    workplan_business_license: 'Business',
    workplan_enterprise_license: 'Enterprise',
  };

  const employeeMapping = {
    1: '5',
    2: '10',
    3: '20',
    4: '30',
  };

  return (
    <div>
      <div className={'px-6 max-w-4xl'}>
        <h2 className="text-2xl font-bold text-primary">{t('onboarding.checkout.overview')}</h2>
        <p className={'my-6'}>
          {t('onboarding.checkout.overview_desc')}
          {t('onboarding.checkout.overview_hint')}
          <a className={'link link-primary'} href={'mailto:support@workplan.digital'}>
            support@workplan.digital
          </a>
          .
        </p>

        <p className={'mt-10'}>{t('onboarding.create_company.company_name')}</p>
        <div className="form-control w-full mt-2">
          <div
            className={
              'px-6 py-3 border border-base-300 rounded w-full flex flex-row justify-between flex-wrap'
            }
          >
            <span className={'text-gray-500'}>{onboarding.affiliate.title}</span>

            <span
              className={'text-blue-500 link'}
              onClick={() => navigate('/onboarding/create-affiliate', { replace: true })}
            >
              {t('general.change')}
            </span>
          </div>
        </div>
        {progressBar.affiliate > 0 && (
          <progress
            className="progress progress-success w-full"
            value={progressBar.affiliate}
            max="100"
          ></progress>
        )}

        <p className={'mt-5'}>{t('onboarding.create_company.company_state')}</p>
        <div className="form-control w-full mt-2">
          <div
            className={
              'px-6 py-3 border border-base-300 rounded w-full flex flex-row justify-between flex-wrap'
            }
          >
            <span className={'text-gray-500'}>
              {t(
                'general_settings.country.germany.state.' +
                  onboarding.affiliate.state.toLowerCase(),
              )}
            </span>

            <span
              className={'text-blue-500 link'}
              onClick={() => navigate('/onboarding/create-affiliate', { replace: true })}
            >
              {t('general.change')}
            </span>
          </div>
        </div>
        {progressBar.configuration > 0 && (
          <progress
            className="progress progress-success w-full"
            value={progressBar.configuration}
            max="100"
          ></progress>
        )}

        <p className={'mt-5'}>{t('onboarding.checkout.license')}</p>
        <div className="form-control w-full mt-2">
          <div
            className={
              'px-6 py-3 border border-base-300 rounded w-full flex flex-row justify-between flex-wrap'
            }
          >
            <span className={'text-gray-500'}>{nameMapping[onboarding.subscription.type]}</span>

            <span
              className={'text-blue-500 link'}
              onClick={() => navigate('/onboarding/select-license', { replace: true })}
            >
              {t('general.change')}
            </span>
          </div>
        </div>

        <p className={'mt-5'}>{t('onboarding.checkout.how_many_employees')}</p>
        <div className="form-control w-full mt-2">
          <div
            className={
              'px-6 py-3 border border-base-300 rounded w-full flex flex-row justify-between flex-wrap'
            }
          >
            <span className={'text-gray-500'}>
              {employeeMapping[onboarding.subscription.number_employees]}
            </span>

            <span
              className={'text-blue-500 link'}
              onClick={() => navigate('/onboarding/select-license', { replace: true })}
            >
              {t('general.change')}
            </span>
          </div>
        </div>
        {progressBar.checkout > 0 && (
          <progress
            className="progress progress-success w-full"
            value={progressBar.checkout}
            max="100"
          ></progress>
        )}
      </div>

      <div className="join grid grid-cols-2 mt-16 w-full sm:w-96 mx-auto px-6">
        <button className="join-item btn btn-outline" onClick={() => previousStep()}>
          {t('general.back')}
        </button>

        {isLoading || checkoutUrl === '' ? (
          <div className={'btn btn-disabled'}>
            <span className={'loading loading-spinner'} /> {t('commerce.licenses.checkout')}
          </div>
        ) : (
          <a
            href={checkoutUrl}
            className={
              'join-item btn btn-outline btn-primary text-white ' +
              (isLoading ? 'btn-disabled' : '')
            }
          >
            {t('commerce.licenses.checkout')}
          </a>
        )}
      </div>
    </div>
  );
}
