import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, setSubscription } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import LicenseSelection from '@/components/Commerce/LicenseSelection';

export default function StepFour() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subscription = useSelector((state: any) => state.onboarding.subscription);

  useEffect(() => {
    dispatch(setStep(4));
  }, []);

  const setLicense = (type) => {
    dispatch(setSubscription({ ...subscription, type }));
  };

  const setNumberEmployees = (number_employees) => {
    dispatch(setSubscription({ ...subscription, number_employees: number_employees }));
  };

  const previousStep = () => {
    navigate('/onboarding/create-affiliate');
  };

  const nextStep = () => {
    navigate('/onboarding/confirm');
  };

  return (
    <div className={'max-w-5xl'}>
      <LicenseSelection
        selectedLicense={subscription?.type}
        selectedNumberEmployees={subscription?.number_employees}
        setLicense={setLicense}
        setNumberEmployeees={setNumberEmployees}
      />

      <div className="join grid grid-cols-2 mt-16 w-full sm:w-96 mx-auto px-6">
        <button className={'join-item btn btn-outline'} onClick={() => previousStep()}>
          {t('general.back')}
        </button>
        <button
          className={
            'join-item btn ' +
            (subscription?.type && subscription?.type === ''
              ? 'btn-outline btn-disabled'
              : 'btn-outline')
          }
          onClick={() => nextStep()}
        >
          {t('general.further')}
        </button>
      </div>
    </div>
  );
}
