import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    process: {
      currentStep: 1,
    },
    affiliate: {
      title: '',
      state: 'None',
    },
    subscription: {
      type: 'workplan_team_license',
      number_employees: 2,
    },
  },
  reducers: {
    setStep(state, action) {
      state.process.currentStep = action.payload;
    },
    setAffiliate(state, action) {
      state.affiliate.title = action.payload.title;
      state.affiliate.state = action.payload.state;
    },
    setSubscription(state, action) {
      state.subscription.type = action.payload.type;
      state.subscription.number_employees = action.payload.number_employees;
    },
  },
});

export const { setStep, setAffiliate, setSubscription, clearState } = onboardingSlice.actions;

export default onboardingSlice.reducer;
