import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAffiliate, setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function StepTwo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const affiliate = useSelector((state: any) => state.onboarding.affiliate);
  const [showError, setShowError] = useState(false);
  const [emptyFields, setEmptyFields] = useState({
    title: affiliate.title.length === 0,
    state: affiliate.state.length === 0,
  });

  useEffect(() => {
    dispatch(setStep(2));
  }, []);

  const nextStep = () => {
    if (emptyFields.title || emptyFields.state) {
      setShowError(true);
      return;
    }

    navigate('/onboarding/select-license');
  };

  const previousStep = () => {
    navigate('/onboarding/get-started');
  };

  const setReduxState = (state) => {
    setEmptyFields({ ...emptyFields, state: state.length === 0 });
    dispatch(setAffiliate({ ...affiliate, state: state }));
  };

  const setReduxTitle = (title) => {
    setEmptyFields({ ...emptyFields, title: title.length === 0 });
    dispatch(setAffiliate({ ...affiliate, title: title }));
  };

  return (
    <div>
      <div className={'px-6 max-w-4xl'}>
        <h2 className="text-2xl font-bold text-primary">{t('onboarding.create_company.title')}</h2>
        <p className={'my-6'}>
          {t('onboarding.create_company.subtitle')}
          <br />
        </p>

        <p className={'mt-10'}>{t('onboarding.create_company.company_name')}</p>
        <div className="form-control w-full mt-2">
          <input
            type="text"
            value={affiliate.title}
            onChange={(e) => setReduxTitle(e.target.value)}
            placeholder="Muster GbR"
            className={
              'input input-bordered input-primary w-full ' +
              (showError && emptyFields.title ? 'border-error' : '')
            }
          />
        </div>

        <p className={'mt-5'}>{t('onboarding.create_company.company_state')}</p>
        <div className="form-control w-full mt-2">
          <select
            name={'state'}
            onChange={(e) => setReduxState(e.target.value)}
            value={affiliate.state}
            className={
              'select select-bordered w-full ' +
              (showError && emptyFields.state ? 'border-error' : '')
            }
          >
            <option value="None">
              {t('general_settings.country.germany.state.none')} ({t('general.no_holidays')})
            </option>
            <option value="DE-BW">{t('general_settings.country.germany.state.de-bw')}</option>
            <option value="DE-BY">{t('general_settings.country.germany.state.de-by')}</option>
            <option value="DE-BE">{t('general_settings.country.germany.state.de-be')}</option>
            <option value="DE-BB">{t('general_settings.country.germany.state.de-bb')}</option>
            <option value="DE-HB">{t('general_settings.country.germany.state.de-hb')}</option>
            <option value="DE-HH">{t('general_settings.country.germany.state.de-hh')}</option>
            <option value="DE-HE">{t('general_settings.country.germany.state.de-he')}</option>
            <option value="DE-NI">{t('general_settings.country.germany.state.de-ni')}</option>
            <option value="DE-MV">{t('general_settings.country.germany.state.de-mv')}</option>
            <option value="DE-NW">{t('general_settings.country.germany.state.de-nw')}</option>
            <option value="DE-RP">{t('general_settings.country.germany.state.de-rp')}</option>
            <option value="DE-SL">{t('general_settings.country.germany.state.de-sl')}</option>
            <option value="DE-SN">{t('general_settings.country.germany.state.de-sn')}</option>
            <option value="DE-ST">{t('general_settings.country.germany.state.de-st')}</option>
            <option value="DE-SH">{t('general_settings.country.germany.state.de-sh')}</option>
            <option value="DE-TH">{t('general_settings.country.germany.state.de-th')}</option>
          </select>
        </div>
        <div className="alert mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-primary shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>{t('onboarding.create_company.subtitle_hint')}</span>
        </div>
      </div>

      <div className="join grid grid-cols-2 mt-16 w-full sm:w-96 mx-auto px-6">
        <button className="join-item btn btn-outline" onClick={() => previousStep()}>
          {t('general.back')}
        </button>
        <button
          className={
            'join-item btn ' +
            (emptyFields.title || emptyFields.state ? 'btn-outline btn-disabled' : 'btn-outline')
          }
          onClick={() => nextStep()}
        >
          {t('general.further')}
        </button>
      </div>
    </div>
  );
}
