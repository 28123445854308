import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import { BsBuildingAdd, BsEnvelopePaper } from 'react-icons/bs';

export default function StepOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  const createAffiliate = () => {
    navigate('/onboarding/create-affiliate');
  };

  const joinAffiliate = () => {
    navigate('/onboarding/join-affiliate');
  };

  return (
    <div className={'px-6 max-w-4xl'}>
      <h2 className="text-2xl font-bold text-primary">{t('onboarding.how_to_start')}</h2>
      <p className={'my-6'}>{t('onboarding.how_to_start_desc')}</p>

      <div className={'grid grid-cols-1 md:grid-cols-2 space-y-5 md:space-y-0 md:space-x-4'}>
        <div
          onClick={createAffiliate}
          className="card flex items-center cursor-pointer justify-center border-2 border-base-300 p-10 hover:text-primary hover:border-primary duration-200"
        >
          <BsBuildingAdd className={'text-gray-400'} size={50} />
          <div className={'mt-2'}>{t('onboarding.create_company.title')}</div>
        </div>

        <div
          onClick={joinAffiliate}
          className="card flex items-center cursor-pointer justify-center border-2 border-base-300 p-10 hover:text-primary hover:border-primary duration-200"
        >
          <BsEnvelopePaper className={'text-gray-400'} size={50} />
          <div className={'mt-2'}>{t('onboarding.join_company.title')}</div>
        </div>
      </div>
    </div>
  );
}
