import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import logo from '@/assets/logo_wp.png';
import { BsCart, BsDoorOpen, BsPerson, BsBuildingAdd } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { clearState as clearUser } from '@/global/userSlice';
import { clearState as clearAffiliate } from '@/global/affiliateSlice';
import { logoutUser } from '@/global/authSlice';

interface OnboardingInterface {
  showLogoutButton: boolean;
}

const OnboardingLayout = ({ showLogoutButton = true }: OnboardingInterface) => {
  const dispatch = useDispatch();
  const process = useSelector((state: any) => state.onboarding.process);
  const { i18n } = useTranslation();
  const language = i18n.language || window.localStorage.i18nextLng;

  const logout = () => {
    dispatch(clearUser());
    dispatch(clearAffiliate());
    dispatch(logoutUser());
  };

  const changeLanguage = (evt) => {
    i18n.changeLanguage(evt.target.value);
  };

  return (
    <div className="relative">
      <div className="h-full">
        <div className="navbar bg-base-100 border-b border-base-300 rounded-none">
          <div className="navbar-start"></div>
          <div className="navbar-center">
            <Link to={'/'}>
              <img alt={'Workplan.Digital Logo'} src={logo} className="w-10" />
            </Link>
          </div>
          <div className="navbar-end">
            {showLogoutButton && (
              <div className={'btn btn-ghost'} onClick={() => logout()}>
                <BsDoorOpen size={17} />{' '}
                <span className={'hidden md:inline-block'}>{t('menu.logout')}</span>
              </div>
            )}
          </div>
        </div>
        <div className={'flex flex-col flex-1 h-full'}>
          {process.currentStep > 1 && process.currentStep < 5 && (
            <ul className="steps mt-20">
              <li className={'step ' + (process.currentStep > 0 && 'step-primary')}>
                <div className={'flex flex-row space-x-2 items-center mt-2'}>
                  <BsPerson className={'inline-block'} size={17} />
                  <span className={'hidden lg:inline-block'}>
                    {t('onboarding.header.register')}
                  </span>
                </div>
              </li>
              <li className={'step ' + (process.currentStep > 1 && 'step-primary')}>
                <div className={'flex flex-row space-x-2 items-center mt-2'}>
                  <BsBuildingAdd size={17} />
                  <span className={'hidden lg:inline'}>
                    {t('onboarding.header.create_company')}
                  </span>
                </div>
              </li>
              {/*}
                          <li className={"step " + (process.currentStep > 2 && "step-primary")}>
                            <div className={"flex flex-row space-x-2 items-center mt-2"}>
                              <BsPersonPlus size={17}/>
                              <span className={"hidden lg:inline"}>Benutzer einladen</span>
                            </div>
                          </li>
                          {*/}
              <li className={'step ' + (process.currentStep > 3 && 'step-primary')}>
                <div className={'flex flex-row space-x-2 items-center mt-2'}>
                  <BsCart size={17} />
                  <span className={'hidden lg:inline'}>
                    {t('onboarding.header.choose_license')}
                  </span>
                </div>
              </li>
            </ul>
          )}

          <div className={'m-auto my-20'}>
            <Outlet />
          </div>
          <div className={'flex flex-row space-x-2 justify-center mb-10'}>
            <select
              className={'select select-md bg-base-200'}
              value={language}
              onChange={changeLanguage}
            >
              <option value={'de'}>DE</option>
              <option value={'en'}>EN</option>
              <option value={'tr'}>TR</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
