import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const RequireAuth = () => {
  const auth = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/onboarding/get-started');
  }, []);

  return auth?.token && user.needs_onboarding ? (
    <Outlet />
  ) : (
    <Navigate to={'/'} state={{ from: location }} replace />
  );
};

export default RequireAuth;
