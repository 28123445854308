import React, { useState } from 'react';

import { AuthLayout } from '@/components/Auth/AuthLayout';
import { AuthPageType } from '@/types/Auth/types.js';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '@/services/UserService';
import { createErrorNotification } from '@/helper/ApiHelper';
import { useTranslation } from 'react-i18next';

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorHighlights, setErrorHighlights] = useState<string[]>([]);
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetFinish, setResetFinish] = useState(false);
  let { token } = useParams();

  const sendResetPasswordEmail = () => {
    setIsLoading(true);
    setResetFinish(false);
    setErrorHighlights([]);

    UserService.setNewPassword(token, password)
      .then(() => {
        setResetFinish(true);
        setPassword('');
      })
      .catch((err) => {
        setErrorHighlights(['password']);
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout authType={AuthPageType.RESET_PASSWORD}>
      <h2 className="card-title">{t('auth.new_password.title')}</h2>
      <div className={'mt-2'}>{t('auth.new_password.description')}</div>
      <div className="form-control w-full max-w-xs mt-2">
        <label className="label">
          <span className="label-text">{t('auth.password')}</span>
        </label>
        <input
          type="password"
          placeholder={t('auth.password')}
          className={[
            errorHighlights.includes('password') ? 'border border-error' : '',
            'input input-bordered w-full max-w-xs',
          ].join(' ')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        {resetFinish && (
          <label className="mt-2 pl-1 text-secondary">
            {t('auth.new_password.success_message')}
          </label>
        )}
        {errorHighlights.includes('password') && (
          <label className="mt-2 pl-1 text-error">{t('auth.new_password.error_message')}</label>
        )}
      </div>
      <button
        className={'btn btn-primary text-white mt-3 '}
        onClick={sendResetPasswordEmail}
        disabled={isLoading || password.length < 4}
      >
        {t('auth.new_password.confirm_button')}
      </button>

      <div className={'btn btn-outline mt-3'} onClick={() => navigate('/auth/login')}>
        {t('general.to') + ' ' + t('auth.login_page')}
      </div>
    </AuthLayout>
  );
}
