import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setStep } from '@/global/onboardingSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function StepThree() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setStep(3));
  }, []);

  const previousStep = () => {
    navigate('/onboarding/create-affiliate');
  };

  const nextStep = () => {
    navigate('/onboarding/select-license');
  };

  return (
    <div className={'text-center w-96'}>
      <h2 className="text-2xl font-bold">Lade Mitarbeiter ein</h2>
      <p className={'mt-10'}>Verschicke Einladungen per E-Mail</p>
      <div className="form-control w-full  mt-2">
        <input
          type="email"
          placeholder="fiona@muster-gbr.de"
          className="input input-bordered input-primary w-full "
        />
      </div>

      <p className={'mt-5'}>Oder erstelle Offline User</p>
      <div className="form-control w-full  mt-2">
        <input
          type="email"
          placeholder="Max Muster"
          className="input input-bordered input-primary w-full "
        />
      </div>

      <div className="join grid grid-cols-2 mt-16 w-full sm:w-96 md:mx-auto px-6">
        <button className="join-item btn btn-outline" onClick={() => previousStep()}>
          {t('general.back')}
        </button>
        <button className="join-item btn btn-outline" onClick={() => nextStep()}>
          {t('general.further')}
        </button>
      </div>
    </div>
  );
}
